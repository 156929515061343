import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Menüs, Tooltips, Datumsauswahlen oder Dialoge – das Web steckt voller Popover-Inhalte. Trotz ihrer weiten Verbreitung
existieren kaum native HTML-Elemente für diese Komponenten (außer für `}<a parentName="p" {...{
        "href": "/de/native-dialog-element.de/"
      }}>{`Dialoge`}</a>{`).
Und ihre Implementierung erfordert eine ganze Menge JavaScript.`}</p>
    <p>{`Doch die neue `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{` in Kombination mit
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-anchor-position-1/"
      }}>{`CSS Anchor Positioning`}</a>{` ändert alles. Die Darstellung von einzelnen
Elementen über anderen Seiteninhalten wird damit für Entwickler:innen zum Kinderspiel.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABq8eayzhg/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIRIf/aAAgBAQABBQLwxg27eixDybLR2G//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEv/aAAgBAwEBPwGT/8QAFREBAQAAAAAAAAAAAAAAAAAAEBL/2gAIAQIBAT8Bo//EABkQAAMAAwAAAAAAAAAAAAAAAAABEQIxMv/aAAgBAQAGPwId5GXZB3Cn/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBUf/aAAgBAQABPyGshGAEcYyoZES/HI/BHIuiQ1ds/9oADAMBAAIAAwAAABATD//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESEx/9oACAEDAQE/EErc1m//xAAXEQADAQAAAAAAAAAAAAAAAAAAESEx/9oACAECAQE/EGNqP//EAB0QAAICAgMBAAAAAAAAAAAAAAERADEhcUFRYfD/2gAIAQEAAT8QYJYLRj8qyoW8/eRTUXKnS4Ngbh1tlTg5ZQWZT//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Skateboarder beim Sprung in der Luft.",
          "title": "Ein Skateboarder beim Sprung in der Luft.",
          "src": "/static/eb944493c38cda4c2efba07a52c43df1/e5166/pexels-zachary-debottis-skateboarder-jump.jpg",
          "srcSet": ["/static/eb944493c38cda4c2efba07a52c43df1/f93b5/pexels-zachary-debottis-skateboarder-jump.jpg 300w", "/static/eb944493c38cda4c2efba07a52c43df1/b4294/pexels-zachary-debottis-skateboarder-jump.jpg 600w", "/static/eb944493c38cda4c2efba07a52c43df1/e5166/pexels-zachary-debottis-skateboarder-jump.jpg 1200w", "/static/eb944493c38cda4c2efba07a52c43df1/b17f8/pexels-zachary-debottis-skateboarder-jump.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Zachary DeBottis / pexels.com`}</em></p>
    <p>{`Ich habe zwei Demos mit Beispielen für Popover-Inhalte vorbereitet. Wir werden sowohl die technischen Aspekte als auch
die Barrierefreiheit der neuen Funktionen eingehend betrachten.`}</p>
    <h2>{`Was können wir damit machen?`}</h2>
    <p>{`Ich mag das Motto: Zeigen, nicht erzählen! Deshalb habe ich zwei Demos erstellt, um die Möglichkeiten der neuen API zu
veranschaulichen.`}</p>
    <p>{`Im Moment funktionieren die Beispiele nur in `}<a parentName="p" {...{
        "href": "https://www.google.com/intl/de/chrome/canary/"
      }}>{`Chrome Canary`}</a>{`, dem Nightly
Build für Entwickler:innen. Öffnet den Browser, ruft die Seite `}<InlineCode mdxType="InlineCode">{`chrome://flags`}</InlineCode>{` auf und aktiviert
dort die experimentellen Web-Plattform-Funktionen.`}</p>
    <p>{`Warnung: Die Anker-Positionierung ist noch sehr experimentell. Daher können die Demos eventuell nicht funktionieren,
falls sich die Spezifikation ändert.`}</p>
    <h3>{`Beispiel 1: Benutzerdefinierter Tooltip`}</h3>
    <p>{`Standard-Tooltips, die mit dem Attribut `}<InlineCode mdxType="InlineCode">{`title`}</InlineCode>{` erstellt werden, sind ziemlich langweilig. Deshalb
habe ich mich an einem schicken `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/dyQRJBv"
      }}>{`benutzerdefinierten Tooltip`}</a>{` versucht.
Klickt auf die Info-Schaltfläche neben der Überschrift:`}</p>
    <iframe title="Barrierefreier Tooltip mit der Popover API" src="https://codepen.io/alexlehner86/embed/dyQRJBv?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/dyQRJBv">
    Accessible Tooltip with Popover API</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Ich habe mein Bestes gegeben, um den Tooltip barrierefrei zu gestalten. Dazu bin ich den Vorgaben des
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/"
      }}>{`Tooltip Pattern`}</a>{` im ARIA Authoring Practices Guide gefolgt. Leider gibt
es noch keinen Konsens zur idealen Implementierung. Darüber hinaus bietet die Popover API noch keinen Mechanismus für Hover-
und Fokus-Events.`}</p>
    <h3>{`Beispiel 2: Menü mit einer Reihe von Aktionen`}</h3>
    <p>{`Ich habe auch eine `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/rNQmERq"
      }}>{`Demo`}</a>{` für ein barrierefreies Menü erstellt. Jeder Eintrag
in der Liste der ausgeliehenen Bücher enthält eine Schaltfläche, die ein Menüfeld mit einer Reihe von Aktionen für jedes Buch
einblendet:`}</p>
    <iframe title="Barrierefreies Menü mit der Popover API" src="https://codepen.io/alexlehner86/embed/rNQmERq?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/rNQmERq">
    Accessible Menu with Popover API</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Meine Implementierung folgt dem `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/menubar/"
      }}><span lang="en">{`Menu and Menubar Pattern`}</span></a>{`,
das im ARIA Authoring Practices Guide definiert ist. Ich habe die entsprechenden ARIA-Rollen und -Eigenschaften verwendet
und auch (die meisten) der empfohlenen Tastatur-Interaktionen implementiert.`}</p>
    <p>{`Schauen wir uns nun die verschiedenen Bausteine an, die ich zur Erstellung dieser Komponenten verwendet habe.`}</p>
    <h2>{`Das magische `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut`}</h2>
    <p>{`Dieses HTML-Attribut verwandelt jedes Element in einen Popover-Inhalt. Die
`}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/multipage/popover.html#the-popover-attribute"
      }}>{`HTML-Spezifikation`}</a>{` besagt:`}</p>
    <blockquote lang="en">
    All HTML elements may have the <BoldText mdxType="BoldText">popover</BoldText> content attribute set. When specified, the element won't be
rendered until it becomes shown, at which point it will be rendered on top of other page content.
    </blockquote>
    <p>{`Dieser Popover-Inhalt ist immer nicht-modal, d. h. Nutzer:innen können weiterhin mit dem Rest der Seite interagieren,
während das Popover geöffnet ist. Wenn ihr ein modales Popover (z. B. einen Bestätigungs-Dialog) anzeigen wollt, solltet
ihr das Element `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` verwenden.`}</p>
    <h2>{`Wie ihr ein Popover erstellt`}</h2>
    <p>{`Um ein Popover zu erstellen, fügt ihr das Attribut `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` zu einem Element hinzu und definiert
eine `}<InlineCode mdxType="InlineCode">{`id`}</InlineCode>{`. Anschließend setzt ihr das Attribut `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` mit dem Wert
der ID des Popovers auf jenes Element, welches das Popover öffnet. Ein Beispiel:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<button popovertarget="popover-1">
    Info
</button>
<div id="popover-1" popover>
    Hi, I'm a popover!
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das war's! Ihr habt gerade ein Popover mit ein paar Zeilen HTML und null JavaScript erstellt. Genial!`}</p>
    <h3>{`Eingebaute Features der Popover API`}</h3>
    <p>{`Wenn ihr das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut verwendet, erhaltet ihr die folgenden Funktionen mit dazu:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Platzierung im Top-Layer:`}</strong>{` Popover-Inhalte werden automatisch im `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
        }}>{`Top-Layer`}</a>{`
angezeigt, einer separaten Ebene, die über allen anderen Inhalten im Webdokument liegt. Ihr müsst euch nicht länger um
die DOM-Reihenfolge oder den z-Index Sorgen machen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Light-dismiss:`}</strong>{` Wenn ihr außerhalb des Popover-Bereichs klickt, wird das Popover geschlossen und der Fokus wieder auf
das auslösende Element zurückgesetzt.`}</li>
      <li parentName="ul"><strong parentName="li">{`Barrierefreie Tastaturbedienung:`}</strong>{` Wenn das Popover geöffnet ist, könnt ihr die `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{`-Taste drücken,
um das Popover zu schließen und den Fokus wieder auf das auslösende Element zu setzen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Semantische Beziehung:`}</strong>{` Die Verknüpfung eines Popover-Elements mit einer Schaltfläche erzeugt automatisch eine semantische
Beziehung für assistive Technologien. Quasi ein eingebautes `}<InlineCode mdxType="InlineCode">{`aria-controls`}</InlineCode>{`
plus `}<InlineCode mdxType="InlineCode">{`aria-expanded`}</InlineCode>{`.`}</li>
    </ul>
    <h3>{`Weitere Optionen und Styling`}</h3>
    <p>{`Wenn ihr ein Popover mit `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` erstellt, blendet die Schaltfläche das Popover standardmäßig ein
und aus. Alternativ könnt ihr auch einen expliziten Wert für das Attribut `}<InlineCode mdxType="InlineCode">{`popovertargetaction`}</InlineCode>{` festlegen:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`"hide"`}</strong>{`: Die Schaltfläche blendet ein angezeigtes Popover aus. Das kann nützlich sein, wenn ihr innerhalb eines Popovers
eine Schaltfläche zum Schließen anbieten möchtet.`}</li>
      <li parentName="ul"><strong parentName="li">{`"show"`}</strong>{`: Die Schaltfläche blendet ein Popover ein.`}</li>
      <li parentName="ul"><strong parentName="li">{`"toggle"`}</strong>{`: Die Schaltfläche blendet ein Popover ein und aus. Dies ist das Standardverhalten.`}</li>
    </ul>
    <p>{`Darüber hinaus ist das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut eigentlich eine Abkürzung für `}<InlineCode mdxType="InlineCode">{`popover="auto"`}</InlineCode>{`.
Dieser Standardtyp von Popover erzwingt das Schließen anderer Auto-Popovers. Es kann über Light-Dismiss oder eine
Schließen-Schaltfläche ausgeblendet werden.`}</p>
    <p>{`Mit `}<InlineCode mdxType="InlineCode">{`popover="manual"`}</InlineCode>{` könnt ihr ein manuelles Popover erstellen. Diese Popover werden nicht über
Light-Dismiss geschlossen und sie erzwingen auch nicht das Schließen anderer Popover. Ihr müsst sie durch eine explizite
Schließ-Aktion ausblenden. Ein möglicher Anwendungsfall für manuelle Popovers sind `}<a parentName="p" {...{
        "href": "https://m2.material.io/components/snackbars"
      }}>{`Alarm-Snackbars`}</a>{`,
die vom Nutzer bestätigt werden müssen.`}</p>
    <p>{`Popovers bieten auch nützliche CSS-Funktionen: Mit dem Pseudoelement `}<InlineCode mdxType="InlineCode">{`::backdrop`}</InlineCode>{` könnt ihr Effekte auf
den Seiteninhalt hinter dem Popover anwenden. Ihr könnt die Pseudoklasse `}<InlineCode mdxType="InlineCode">{`:popover-open`}</InlineCode>{` verwenden,
um das Styling von eingeblendeten Popover-Elementen zu definieren.`}</p>
    <h2>{`CSS Anchor Positioning`}</h2>
    <p>{`Wenn ihr's bis hierher geschafft habt, fragt ihr euch vielleicht: Was ist mit der Position des Popovers auf der Seite? Um
den eingeblendeten Inhalt relativ zu einem anderen Element zu positionieren, benötigen wir eine weitere neue, experimentelle
Funktion: Anker-Positionierung.`}</p>
    <p>{`Dieses Feature ist im CSS-Modul `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-anchor-position-1/"
      }}>{`CSS Anchor Positioning`}</a>{` definiert. Im Moment
ist das Dokument noch ein `}<ItalicText mdxType="ItalicText">{`„Working Draft“`}</ItalicText>{`, aber Chrome bietet die Funktion bereits hinter
dem Flag für experimentelle Web-Plattform-Funktionen an. Das Dokument besagt:`}</p>
    <blockquote lang="en">
    This specification defines <BoldText mdxType="BoldText">'anchor positioning'</BoldText>, where a positioned element can size and position itself
relative to one or more "anchor elements" elsewhere on the page.
    </blockquote>
    <p>{`In meinen Demos habe ich die Anker-Positionierung verwendet, um den Tooltip und die Menüfelder neben ihren Toggle-Schaltflächen
zu platzieren. Sehen wir uns an, wie wir Anker definieren und sie im CSS-Code referenzieren können.`}</p>
    <h3>{`Wie ihr ein Anker-Element definiert`}</h3>
    <p>{`Es gibt zwei verschiedene Wege, um Anker zu definieren. Ihr könnt ein HTML-Element in einen Anker verwandeln, indem ihr dem
Element eine `}<InlineCode mdxType="InlineCode">{`id`}</InlineCode>{` zuweist, und dann das `}<InlineCode mdxType="InlineCode">{`anchor`}</InlineCode>{`-Attribut verwendet, um ein anderes
Element an diesen Anker zu binden. Hier ist ein Beispiel in Kombination mit einem Popover:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<button id="toggle-btn-1" popovertarget="popover-1" class="toggle-btn">
    Info
</button>
<div id="popover-1" popover anchor="toggle-btn-1">
    Hi, I'm a popover!
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Alternativ könnt ihr die CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`anchor-name`}</InlineCode>{` verwenden, um einen Anker zu definieren. Diese
Eigenschaft akzeptiert einen `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/dashed-ident"
      }}>{`dashed-ident`}</a>{` Wert wie
etwa `}<InlineCode mdxType="InlineCode">{`--toggle-btn`}</InlineCode>{`. Anschließend nutzt ihr die Eigenschaft `}<InlineCode mdxType="InlineCode">{`position-anchor`}</InlineCode>{`,
um ein anderes Element an den Anker zu binden.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`button.toggle-btn {
    anchor-name: --toggle-btn;
}
div[popover] {
    position-anchor: --toggle-btn;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Inhalte mit der `}<InlineCode mdxType="InlineCode">{`anchor()`}</InlineCode>{` Funktion positionieren`}</h3>
    <p>{`Gut, ihr habt jetzt ein HTML-Element als Anker definiert und ein Popover daran gebunden. Jetzt könnt ihr die
Funktion `}<InlineCode mdxType="InlineCode">{`anchor()`}</InlineCode>{` verwenden, um das Popover zu positionieren. Die Funktion akzeptiert drei Argumente:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Anker-Element`}</strong>{`: Dieser Wert gibt an, wo sich das Anker-Element befindet. Ihr könnt einen expliziten Ankernamen angeben.
Oder ihr lasst den Wert weg, um den impliziten Anker zu verwenden, der über das Attribut `}<InlineCode mdxType="InlineCode">{`anchor`}</InlineCode>{` oder
die CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`position-anchor`}</InlineCode>{` definiert wurde.`}</li>
      <li parentName="ul"><strong parentName="li">{`Verankerungs-Seite`}</strong>{`: Dieser Wert bezieht sich auf die Position der entsprechenden Seite des Anker-Elements. Zum
Beispiel: `}<InlineCode mdxType="InlineCode">{`bottom`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`left`}</InlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fallback (optional)`}</strong>{`: Ein Längen- oder prozentualer Wert, der als Fallback im Falle einer ungültigen Ankerfunktion dient.`}</li>
    </ul>
    <p>{`Das folgende Beispiel zeigt, wie ihr einen Tooltip unterhalb der Toggle-Schaltfläche platzieren und horizontal zentrieren
könnt:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`[role="tooltip"][popover] {
    position: absolute;
    position-anchor: --toggle-btn;
    top: anchor(bottom);
    left: anchor(center);
    translate: -50% 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Die Spezifikation enthält noch weitere Funktionen wie die Eigenschaft `}<InlineCode mdxType="InlineCode">{`anchor-scroll`}</InlineCode>{` und die
Funktion `}<InlineCode mdxType="InlineCode">{`anchor-size()`}</InlineCode>{`. Aber der Blog-Beitrag ist schon lang genug, daher bin ich mal so frech und
lasse sie aus.`}</p>
    <h2>{`Barrierefreiheit von Popovers`}</h2>
    <p>{`Sind Popovers barrierefrei? Wie meine Beispiele oben zeigen, könnt ihr mit der neuen Popover-API nutzerfreundliche,
barrierefreie Komponenten erstellen. Aber das passiert nicht von selbst.`}</p>
    <h3>{`Definiert die passende semantische Rolle`}</h3>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut kann die Barrierefreiheit eurer Website auf jeden Fall verbessern. Zum Beispiel
durch die Möglichkeit, das Popover mit der `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{`-Taste zu schließen. Oder durch die semantische Beziehung
zwischen dem Toggle-Button und dem Popover, die durch das Attribut `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` erzeugt wird.`}</p>
    <p>{`Andererseits definiert das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut keine implizite Rolle für das HTML-Element, dem es
hinzugefügt wird. Das bedeutet: Denkt über euren konkreten Anwendungsfall nach und verwendet dann das passende
native HTML-Element oder die angemessene `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles"
      }}>{`ARIA-Rolle`}</a>{`
(wie zum Beispiel `}<InlineCode mdxType="InlineCode">{`tooltip`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{`).`}</p>
    <h3>{`Screenreader-Unterstützung`}</h3>
    <p>{`Wie erleben Nutzer:innen von Screenreadern die Interaktion mit Webinhalten, die mit der Popover-API erstellt wurden?
Aufgrund der begrenzten Browser-Unterstützung für die neuen Features habe ich meine Demos nur in den folgenden Szenarien
getestet:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.nvaccess.org/download/"
        }}>{`NVDA`}</a>{` 2023.1, Windows 11, Google Chrome Chanary 117.0.5876.0`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://support.google.com/accessibility/android/topic/10601571?hl=de"
        }}>{`TalkBack`}</a>{`, Samsung Galaxy S20, Android 13,
Google Chrome Chanary 117.0.5876.0`}</li>
    </ol>
    <p>{`Beide Demos haben super funktioniert: Ich konnte mit der Tastatur durch den Tooltip und die Menüs navigieren, während NVDA
die semantischen Rollen und den aktuellen Status der Steuerelemente vorlas.`}</p>
    <p>{`Auch die Interaktion auf meinem Smartphone verlief reibungslos: Bei aktiviertem TalkBack navigierte ich mit
`}<a parentName="p" {...{
        "href": "https://support.google.com/accessibility/android/answer/6006598?hl=de"
      }}>{`Wischgesten`}</a>{` durch die Seiten, während der
Screenreader mich über den Inhalt, die semantischen Rollen und den aktuellen Status der Bedienelemente informierte.`}</p>
    <p>{`Das stimmt mich wirklich optimistisch für die Zukunft des Webs! Ich werde noch weitere Tests mit anderen Browsern und
Screenreadern durchführen, sobald die Popover-API besser unterstützt wird.`}</p>
    <h2>{`Browser-Unterstützung`}</h2>
    <p>{`Wann können wir die Popover-API und CSS Anchor Positioning produktiv einsetzen? Leider wird es noch einige Zeit dauern,
bis es eine browserübergreifende Unterstützung für diese Features geben wird.`}</p>
    <p>{`Laut `}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-api_htmlelement_popover"
      }}>{`caniuse.com`}</a>{` wird das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` Attribut
bereits von Chrome und Edge unterstützt. In der
`}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/safari-technology-preview-release-notes/stp-release-170"
      }}>{`Safari 17 Technology Preview`}</a>{`
wird das Attribut angekündigt, während `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Experimental_features"
      }}>{`Firefox`}</a>{`
es nur als experimentelles Feature anführt (ihr müsst das `}<InlineCode mdxType="InlineCode">{`dom.element.popover.enabled`}</InlineCode>{` Flag setzen).`}</p>
    <p>{`Die browserübergreifende Unterstützung für `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-anchor-position-1/"
      }}>{`CSS Anchor Positioning`}</a>{` wird wohl
noch länger dauern. Soweit ich weiß hat bisher nur Chrome das experimentelle Feature implementiert. Aufgrund des Status
als `}<span lang="en">{`„First Public Working Draft“`}</span>{` kann sich die aktuelle Spezifikation in Zukunft noch ändern. Wir müssen
einfach abwarten und sehen was passiert.`}</p>
    <h2>{`Fazit`}</h2>
    <p>{`Die neue Popover-API in Kombination mit CSS Anchor Positioning wird unser Leben als Web-Entwickler:innen einfacher machen und
zu mehr Barrierefreiheit im Web beitragen.`}</p>
    <p>{`Trotz des noch experimentellen Status der neuen APIs solltet ihr sie selbst ausprobieren und euch mit den neuen Attributen und
Eigenschaften vertraut machen. Auf diese Weise seid ihr dann schon startklar, wenn die Features endlich von allen modernen
Browsern unterstützt werden. 😎`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
        }}>{`Popover API (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://html.spec.whatwg.org/multipage/popover.html#the-popover-attribute"
        }}>{`HTML specification for the popover attribute`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://open-ui.org/components/popover.research.explainer/"
        }}>{`Popover API Explainer (Open UI)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/css-anchor-position-1/"
        }}>{`CSS Anchor Positioning (Draft)`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update am 05.04.2024</PostUpdateTitle>
    <p>{`Code-Beispiele und Erläuterungen überarbeitet, um der aktualisierten CSS Anchor Positioning Spezifikation
(W3C Working Draft, 26. März 2024) sowie der Implementierung in Chrome Canary zu entsprechen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      